import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from './store'
import VueTheMask from 'vue-the-mask'
import Vue2Filters from "vue2-filters";

Vue.config.productionTip = false

Vue.use(VueTheMask)
Vue.use(Vue2Filters);


/**
 * Axios
 * baseURL = API URL
 */
axios.defaults.baseURL = process.env.VUE_APP_API_URL;
Vue.use(VueAxios, axios);


/**
* Vee-validate
*/
import './vee-validate';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);


/**
 * Globally available components
 */
import VeeTextField from './components/inputs/VeeTextField'
import VeeSelect from './components/inputs/VeeSelect'
import Card from "./components/common/Card"
import CardSubtitle from "./components/common/CardSubtitle"
import ErrorTemplate from "./components/common/ErrorTemplate"
import CheckoutLogo from "./components/common/CheckoutLogo.vue"
Vue.component('VeeTextField', VeeTextField);
Vue.component('VeeSelect', VeeSelect);
Vue.component('Card', Card);
Vue.component('CardSubtitle', CardSubtitle);
Vue.component('ErrorTemplate', ErrorTemplate);
Vue.component('CheckoutLogo', CheckoutLogo);

new Vue({
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
