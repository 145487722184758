import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

export default new Vuex.Store({
  // plugins: [createPersistedState({
  //   storage: window.sessionStorage
  // })],

  state: {
    dev: false,
    initialLoading: true,
    product: {
      id: null,
      price: null,
      quantity: null,
      description: '',
      seller: {
        name: '',
        success_url: ''
      },
      img: '',
    },
    window: { // populated by App.vue
      width: 0,
      height: 0
    },
    layout: {
      dark: false,
      bgImage: false, // use only with dark: true
      dimCard: false,
      dimCardColor: 'rgba(255,255,255,0.96)',
      singleCol: true,
      singleColWidth: 700,
      singleColRect: { // populated by Payment.vue
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
      },
    },
    fbPixel: {
      codes: [175298111251946],
    }
  },
  mutations: {
    setProduct (state, payload) {
      state.product = payload
    },
    setInitialLoading (state, payload) {
      state.initialLoading = payload
    },
    setWindowSize (state, payload) {
      state.window.width = payload.width
      state.window.height = payload.height
    },
    setSingleColRect (state, payload) {
      state.layout.singleColRect = payload;
    },
    addFbPixelCode (state, code) {
      state.fbPixel.codes.push(code);
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
    initialLoading(state) {
      return state.initialLoading;
    },
    product(state) {
      return state.product && state.product.id ? state.product : false;
    },
    seller(state) {
      return state.product.seller ? state.product.seller : false;
    }
  }
})
