<template>
  <v-card-subtitle 
    class="pl-0 pb-0 mb-2 text-h6"
    :class="disabled ? 'grey--text' : ''"
  >
    {{text}}
    <slot/>
  </v-card-subtitle>
</template>
<script>
export default {
    props: {
      text: String,
      disabled: {
        type:Boolean,
        default: false
      },
    },
    data() {
      return {
      }
    },
    methods: {
    }
}
</script>