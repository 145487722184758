<template>
  <!-- Resumo da compra -->
  <div>
    <CardSubtitle>
      Resumo da compra
    </CardSubtitle>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            Produto
          </v-list-item-subtitle>
          <v-list-item-title>
            {{form.description}}
          </v-list-item-title>
          <v-list-item-title>
            Vendido por {{$store.getters.seller.name}}
          </v-list-item-title>
          <v-list-item-title v-if="$store.state.product.quantity > 1">
            {{$store.state.product.quantity}} {{$store.state.product.quantity > 1 ? 'unidades' : 'unidade'}}
          </v-list-item-title>
          <v-list-item-title v-if="$store.state.product.quantity > 1">
            Preço por unidade: {{
              $store.state.product.price 
              | currency("R$", 2, {
              decimalSeparator: ",",
              thousandsSeparator: ".",
              spaceBetweenAmountAndSymbol: true
              })
            }}
          </v-list-item-title>
          <v-list-item-title>
            Total da compra: <b>{{
              form.transaction_amount 
              | currency("R$", 2, {
              decimalSeparator: ",",
              thousandsSeparator: ".",
              spaceBetweenAmountAndSymbol: true
              })
            }}</b>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            Seus dados
          </v-list-item-subtitle>
          <v-list-item-title>
            {{form.name}}
          </v-list-item-title>
          <v-list-item-title>
            {{form.docType}} {{form.docNumber}}
          </v-list-item-title>
          <v-list-item-title>
            {{form.email}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle>
            Forma de pagamento
          </v-list-item-subtitle>
          <v-list-item-title>
            {{paymentTypeText}}
          </v-list-item-title>
          <v-list-item-title v-if="paymentType === 1">
            {{form.address.street_name +', N. '+ form.address.street_number +', CEP '+ form.address.zip_code +', '+ form.address.city +' - '+ form.address.federal_unit}} 
          </v-list-item-title>
          <v-list-item-title v-else-if="paymentType === 2">
            {{installmentText}}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
export default {
  name: 'TransactionSummary',
  props: [
    "paymentType",
    "form",
    "installments"
  ],
  data() {
    return {
    }
  },
  computed: {
    paymentTypeText() {
      let text = ''
        switch (this.paymentType) {
            case 1: 
              text = 'Boleto bancário'
              break;
            case 3: 
              text = 'Pix'
              break;
            default:
              text = 'Cartão de crédito - final ' + this.form.cardNumber.substring(this.form.cardNumber.length -4)
        }
      return text;
    },
    installmentText() {
      if (this.installments.length > 0 && this.form.installments) {
        return this.installments.filter( inst => {
          return inst.value === this.form.installments
        })[0].text
      }
      return 'Sem parcelamento'
    }
  }
}
</script>
<style scoped>
.v-list-item__title {
  white-space: normal !important;
}
.v-list-item {
  padding-left: 0px;
}
/* .v-list-item__content {
  padding-bottom: 0px !important;
} */
</style>