<template>
  <div
    class="d-flex flex-column justify-center align-center rounded-circle text-center"
    style="width: 100px; height: 100px; border: 0px none transparent !important"
  >
    <v-avatar 
      size="46"
      :color="color"
    >
      <slot />
    </v-avatar>

    <div class="" style="line-height:1rem;">
      <div class="body-1 grey--text" :class="dark ? 'text--lighten-1' : 'text--darken-1'">
        {{text.split(' ')[0]}}
      </div>
      <div class="text-uppercase font-weight-bold" :class="textColor">
        {{text.split(' ')[1]}}
      </div>
    </div>
  </div>
</template>
<script>

export default {
    props: {
      text: String,
      color: String,
      titleIcon: String,
      dark: Boolean,
      disabled: {
        type: Boolean,
        default: false
      },
      error: {
        type: Object,
        default: function () {
          return { msg: '' }
        }
      }
    },
    data() {
      return {
      }
    },
    computed: {
      textColor() {
        let color = this.color.split(' ')[0];
        let shade = this.color.split(' ')[1];

        return !shade
        ? color + '--text'
        : color + '--text ' + 'text--' + shade
      }
    },
    methods: {
    }
}
</script>