<template>
  <div>
      <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              ref="copyBtn"
              :icon="!$vuetify.breakpoint.xsOnly"
              :class="$vuetify.breakpoint.xsOnly ? 'mb-3' : ''"
              @click="doCopy()">
                  <v-fade-transition hide-on-leave>
                      <v-icon v-show="!success">mdi-content-copy</v-icon>
                  </v-fade-transition>
                  <v-fade-transition hide-on-leave>
                      <v-icon v-show="success">mdi-check</v-icon>
                  </v-fade-transition>
                  <span v-if="$vuetify.breakpoint.xsOnly">
                    Copiar {{text || ''}}
                  </span>
              </v-btn>
          </template>
          Copiar {{text || ''}}
      </v-tooltip>
  </div>
</template>
<script>

export default {
    props: ["text", "item"],
    data() {
      return {
          success: undefined,
      }
    },
    methods: {
        async doCopy() {
            try {
                await navigator.clipboard.writeText(this.item);
                console.log('Item copied to clipboard');
                this.success = true;
            } catch (err) {
                console.error('Failed to copy to clipboard: ', err);
                this.success = false;
            } finally {
                setTimeout(() => {
                  this.success = undefined;    
                }, 1500);
            }
        }
    }
}
</script>