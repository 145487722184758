<template>
  <ValidationProvider
    ref="provider"
    :name="$attrs.name || $attrs.label"
    :rules="rules"
    v-slot="{ errors, valid, touched }"
    :debounce="250"
  >
    <!-- {{$attrs}} -->
    <v-text-field
      v-model="innerValue"
      :error-messages="errors"
      :success="touched && valid && innerValue !== ''"
      v-bind="$attrs"
      v-on="$listeners"
      :class="classes"
      :style="styles"
    ></v-text-field>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";

export default {
  components: {
    ValidationProvider
  },
  props: {
    rules: {
      type: [Object, String],
      default: ""
    },
    // must be included in props
    value: {
      type: null
    },
    classes: {
      type: String,
      default: ""
    },
    styles: {
      type: String,
      default: ""
    }
  },
  data: () => ({
    innerValue: ""
  }),
  watch: {
    // Handles internal model changes.
    innerValue(newVal) {
      this.$emit("input", newVal);
    },
    // Handles external model changes.
    value(newVal) {
      this.innerValue = newVal;
    }
  },
  computed: {
  },
  created() {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
};
</script>
