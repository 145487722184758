<template>
  <v-container fill-height fluid :style="$store.state.layout.dark ? 'background-color: var(--v-ymblack-base);' : 'background-color: var(--v-lightBg-base)'" style="height:100vh;">
    <v-row class="align-center justify-center">
      <v-col>
        <div class="mx-auto d-flex align-center justify-center">
          <v-progress-circular
            :size="60"
            indeterminate
            style="opacity: 0.18"
          />
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "PaymentLoader",
  components: {
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>
</style>