<template>
  <div>
    <v-card 
      :flat="flat ? true : false" 
      :class="disabled ? 'grey--text' : ''"
      :color="$store.state.layout.dimCard ? $store.state.layout.dimCardColor : ''"
    >
      <v-card-title 
        :class="setTitleColor" 
        class="d-flex flex-nowrap"
        style="word-break: break-word;"
      >
        <v-icon
          v-if="titleIcon"
          left
          :color="titleColor ? titleColor : ''"
        >{{titleIcon}}</v-icon>
        {{title}}
      </v-card-title>

      <slot />

      <v-card-actions v-if="hasActions" class="mr-2">
        <slot name="actions"/>
      </v-card-actions>

      <slot name="end"/>

    </v-card>
    <v-expand-transition>
      <v-alert type="error" v-if="error.msg" class="mt-3">
        {{error.msg}}
      </v-alert>
    </v-expand-transition>
  </div>
</template>
<script>

export default {
    props: {
      flat: Boolean,
      title: String,
      titleIcon: String,
      titleColor: String,
      disabled: {
        type:Boolean,
        default: false
      },
      error: {
        type: Object,
        default: function () {
          return { msg: '' }
        }
      }
    },
    data() {
      return {
      }
    },
    computed: {
      hasActions() {
        return !!this.$slots.actions;
      },
      setTitleColor() {
        if (!this.titleColor) return '';

        let color = this.titleColor.split(' ')[0];
        let shade = this.titleColor.split(' ')[1];

        return !shade
        ? color + '--text'
        : color + '--text ' + 'text--' + shade 
      }
    },
    methods: {
    }
}
</script>