<template>
  <div 
    class="bgLogoCol d-flex flex-column align-center"
    v-if="$store.state.layout.singleCol && $store.state.window.width >= 830" 
    :style="{width: colWidth}"
    >
    <div
      class="ymlogo d-flex flex-column justify-start align-end mb-9 pr-3 pt-3"
    >
      <CheckoutLogo 
        :width="250"
      />
    </div>
    <div class="badges">
      <ShieldBadge
        text="Dados protegidos"
        color="blue lighten-1"
        class="mb-9"
        dark
      >
        <v-icon dark>mdi-lock-check</v-icon>
      </ShieldBadge>
      <ShieldBadge
        text="Compra segura"
        color="green"
        class="mb-9"
        dark
      >
        <v-icon dark>mdi-shield-check</v-icon>
      </ShieldBadge>
      <img 
        alt="Mercado Pago"
        :src="require('@/assets/images/mercadopago-vert.png')"
        height="100"
        class="mb-9"
      />
    </div>
  </div>
</template>


<script>

let el = document.getElementById('paymentWrapper');

import ShieldBadge from "@/components/common/ShieldBadge";

export default {
  name: 'BgLogoCol',

  components: {
    ShieldBadge
  },

  data: () => ({
    // 
  }),

  computed: {
    colWidth() {
      return this.$store.state.window.width - this.$store.state.layout.singleColRect.right + 'px';
    }
  },

  created() {
    // 
  },
  
  mounted() {
    // 
  },

  methods: {
    // 
  },

};

</script>
<style lang="css">
.bgLogoCol {
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100vh;
}
</style>