<template>
 <Card
    :title="title"
    titleIcon="mdi-check-circle"
    titleColor="green"
  >
    <v-card-text>
      <v-row>
        <v-col v-if="paymentType !== 2" cols="12" md="6">
          <!-- Boleto -->
          <div v-if="paymentType === 1">
            <CardSubtitle class="mb-6">
              Sua compra será finalizada com o pagamento do boleto. 
            </CardSubtitle>
            <CodeBox 
              v-if="paymentType === 1 && boletoUrl"
              :code="boletoUrl"
              text="Link do boleto"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn 
                v-if="paymentType === 1 && boletoUrl"
                color="primary"
                dark
                @click="$emit('showBoleto')"
                :block="$vuetify.breakpoint.xsOnly"
              >
                Ver boleto
              </v-btn>
            </v-card-actions>
          </div>
          <!-- Pix QR Code -->
          <v-row v-else-if="paymentType === 3 && !pixPaid">
            <v-col class="text-center mb-6">
              <CardSubtitle class="text-left">
                Para pagar com o Pix, basta escanear o QR Code abaixo com o aplicativo do seu banco: 
              </CardSubtitle>
              <v-img :src="'data:image/jpeg;base64,'+ qrcode.img" style="max-width:320px;" class="mx-auto"/>
              <div>
                <b>Código válido por 30 minutos.</b>
                <br/>
                Se preferir, você pode pagá-lo copiando e colando o código abaixo:
              </div>
              <CodeBox 
                :code="qrcode.url"
                text="QR Code"
              />
            </v-col>
          </v-row>
          <!-- Pix Pago -->
          <v-row v-else-if="paymentType === 3 && pixPaid">
            <v-col class="text-center mb-6">
              <CardSubtitle class="text-left">
                Seu pagamento com o QR Code do Pix foi finalizado com sucesso! 
              </CardSubtitle>
            </v-col>
          </v-row>
        </v-col>

        <!-- Resumo da compra -->
        <v-col cols="12" md="6" class="pl-0 pl-md-9">
          <TransactionSummary 
            :form="form"
            :paymentType="paymentType"
            :installments="installments"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <template #actions>
      <v-spacer/>
      <v-btn 
        v-if="paymentType === 2 || (paymentType === 3 && pixPaid)"
        color="success"
        dark
        target="_blank"
        :href="$store.getters.seller.success_url"
      >
        Voltar para a página do vendedor
      </v-btn>
    </template>

  </Card>
</template>
<script>
import CodeBox from '@/components/common/CodeBox.vue'
import TransactionSummary from '@/components/payment/TransactionSummary.vue'

export default {
  name: 'PaymentSuccess',
  components: {
    CodeBox,
    TransactionSummary
  },
  props: [
    "success",
    "errors",
    "hasError",
    "paymentType",
    "boletoUrl",
    "form",
    "qrcode",
    "installments"
  ],
  data() {
    return {
      pixPaid: false,
    }
  },
  computed: {
    title() {
      let text = ''
        switch (this.paymentType) {
            case 1: 
              text = 'Seu boleto foi gerado com sucesso!'
              break;
            case 3: 
              text = this.pixPaid ? 'Sua compra foi realizada com sucesso!' : 'QR Code gerado com sucesso!'
              break;
            default:
              text = 'Sua compra foi realizada com sucesso!'
        }
      return text;
    },
  }
}
</script>
