<template>
  <div 
    id="paymentWrapper"
    ref="paymentWrapper"
    :class="$store.state.layout.singleCol && $store.state.window.width <= 1160 ? 'mr-auto' : 'mx-auto'" 
    :style="$store.state.layout.singleCol ? 'max-width:'+ $store.state.layout.singleColWidth +'px;' : ''"
  >
    <PaymentDialog 
      :dialog="dialog"
      :success="success"
      :paymentType="paymentType"
      :boletoUrl="boletoUrl"
      :form="form"
      :hasError="hasError"
      :errors="errors"
      @close="dialog = false, clearErrors()"
    />
    <PaymentOverlay 
      :loading="loading"
      :paymentType="paymentType"
    />
    <PaymentLoader 
      v-show="$store.getters.initialLoading"
    />
    <v-fade-transition>
      <ErrorTemplate v-show="!$store.getters.initialLoading && !hasProduct">
        <v-alert type="error" text>
          <h3 class="font-weight-regular">
            Produto indisponível
          </h3>
        </v-alert>
        <div class="body-1">
          Infelizmente não conseguimos encontrar o produto que você está procurando.
        </div>
      </ErrorTemplate>
    </v-fade-transition>
    <v-fade-transition>
      <v-window 
        :value="payWindow"
        v-show="!$store.getters.initialLoading && hasProduct"
      >
        <v-row>
          <v-col class="pb-6">
            <v-sheet flat class="green--text text--darken-2 text-center mb-3 font-weight-bold" color="transparent" v-if="$vuetify.breakpoint.xsOnly" v-show="false">
              <v-icon color="green">mdi-shield-check</v-icon>
              Compra 100% Segura
            </v-sheet>
            <v-toolbar
              elevation="1"
              rounded
              height="auto"
              class="py-2 py-sm-3"
              :color="$store.state.layout.dimCard ? $store.state.layout.dimCardColor : ''"
            >
              <v-avatar 
                size="52"
                v-if="$store.state.product.img"
              >
                <v-img :src="$store.state.product.img"></v-img>
              </v-avatar>
              <v-icon v-else>mdi-cart</v-icon>
              <v-toolbar-title class="ml-4 d-flex flex-column" style="white-space: normal;">
                <span class="overline grey--text text--darken-2 mb-n2">
                  Você está adquirindo: 
                </span>
                <span>{{form.description}}</span>
              </v-toolbar-title>
              <v-spacer />
              <v-sheet flat class="transparent green--text text--darken-2 font-weight-bold" v-if="$vuetify.breakpoint.smAndUp" v-show="false">
                <v-icon color="green">mdi-shield-check</v-icon>
                Compra 100% Segura
              </v-sheet>
            </v-toolbar>
          </v-col>
        </v-row>

        <!-- Payment Form -->
        <v-window-item :value="1" eager>
          <ValidationObserver ref="observer">
          <v-form 
            id="paymentForm"
            ref="paymentForm"
            autocomplete="on"
          >
            <v-row>
              <!-- COL USER -->
              <v-col cols="12" :lg="$store.state.layout.singleCol ? 12 : 6">
                <Card title="Informe os seus dados" :disabled="success">
                  <v-card-text class="mb-0">
                    <VeeTextField
                      id="name"
                      outlined
                      prepend-icon="mdi-account"
                      rules="required|min:3|max:255"
                      v-model="form.name"
                      label="Nome completo ou Razão social"
                      classes="mb-3"
                      :disabled="success"
                    />
                    <VeeTextField
                      outlined
                      prepend-icon="mdi-card-account-details"
                      :rules="form.docNumber.length > 14 ? 'required|min:14|max:18' : 'required|min:14|max:18|cpf'"
                      v-model="form.docNumber"
                      label="CPF ou CNPJ"
                      classes="mb-3"
                      :disabled="success"
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      hint="Digite somente os números"
                      @change="setDocType()"
                      @input="setDocType()"
                    />
                      <!-- id="docNumber"
                      data-checkout="docNumber"
                      :value="form.docNumber" -->
                    <input
                      type="hidden"
                      id="docNumber"
                      name="docNumber"
                      data-checkout="docNumber"
                      :value="format(form.docNumber)"
                    />
                    <input
                      type="hidden"
                      id="docType"
                      name="docType" 
                      data-checkout="docType"
                      v-model="form.docType"
                    />
                    <VeeTextField
                      v-if="!hiddenFields.includes('cel')"
                      id="cel"
                      outlined
                      prepend-icon="mdi-cellphone"
                      rules="required|min:15|max:15"
                      v-model="form.cel"
                      label="Celular com DDD"
                      classes="mb-3"
                      :disabled="success"
                      v-mask="'(##) #####-####'"
                      hint="Digite somente os números"
                    />
                    <VeeTextField
                      id="email"
                      ref="email"
                      name="Email"
                      outlined
                      prepend-icon="mdi-email"
                      rules="required|email"
                      v-model="form.email"
                      label="E-mail"
                      classes="mb-1"
                      :disabled="success"
                    />
                    <VeeTextField
                      outlined
                      prepend-icon="mdi-email-check"
                      rules="required|email|confirmed:@Email"
                      v-model="form.email_conf"
                      label="Confirme o e-mail"
                      classes="mb-3 pl-0"
                      :disabled="success"
                    />
                  </v-card-text>
                </Card>
              </v-col>

              <!-- COL PAYMENT -->
              <v-col cols="12" :lg="$store.state.layout.singleCol ? 12 : 6">
                <Card title="Escolha o método de pagamento" :disabled="success">
                  <!-- <v-card-subtitle>Escolha o método de pagamento</v-card-subtitle> -->
                  <v-card-text class="mb-0">
                    <ItemSelector
                      class="mt-0 mb-6"
                      :items="paymentTypes"
                      :model="paymentType"
                      :disabled="success"
                      @update="setPaymentType"
                    />

                    <v-window :value="paymentType">
                      <!-- BOLETO -->
                      <v-window-item :value="1" eager>
                        <v-row v-if="paymentType === 1">
                          <v-col cols="12" md="12" class="pt-0 mt-0">
                            <CardSubtitle :disabled="success">
                              Endereço para emissão de boleto
                            </CardSubtitle>
                          </v-col>
                          <v-col cols="12" md="8" class="py-0 mt-0 mb-2">
                            <VeeTextField
                              outlined
                              rules="required|min:3|max:255"
                              v-model="form.address.street_name"
                              label="Logradouro"
                              hint="Rua, avenida, praça etc"
                              :disabled="success"
                            />
                          </v-col>
                          <v-col cols="12" md="4" class="py-0 mt-0 mb-2">
                            <VeeTextField
                              outlined
                              rules="required|min:1|max:255"
                              v-model="form.address.street_number"
                              label="Número"
                              v-mask="['###########']"
                              :disabled="success"
                            />
                          </v-col>
                          <v-col cols="12" md="8" class="py-0 my-0">
                            <VeeTextField
                              outlined
                              rules="required|max:255"
                              v-model="form.address.neighborhood"
                              label="Bairro"
                              :disabled="success"
                            />
                          </v-col>
                          <v-col cols="12" md="4" class="py-0 mt-0 mb-2">
                            <VeeTextField
                              outlined
                              rules="required|min:9|max:9"
                              v-model="form.address.zip_code"
                              label="CEP"
                              v-mask="['#####-###']"
                              hint="Digite somente os números"
                              :disabled="success"
                            />
                          </v-col>
                          <v-col cols="12" md="8" class="py-0 my-0">
                            <VeeTextField
                              outlined
                              rules="required|max:255"
                              v-model="form.address.city"
                              label="Cidade"
                              :disabled="success"
                            />
                          </v-col>
                          <v-col cols="12" md="4" class="py-0 my-0">
                            <VeeSelect
                                outlined
                                rules="required"
                                v-model="form.address.federal_unit"
                                label="Estado"
                                :items="UFs"
                                item-value="sigla"
                                item-text="sigla"
                              />
                          </v-col>
                        </v-row>
                      </v-window-item>

                      <!-- CREDIT CARD -->
                      <v-window-item :value="2" eager>
                        <v-row v-if="paymentType === 2">
                          <v-col cols="12" md="12" class="pt-0 mt-0">
                            <CardSubtitle :disabled="success">
                              Preencha os dados do cartão de crédito
                            </CardSubtitle>
                            <div class='card-wrapper mb-6'></div>
                              <VeeTextField 
                                rules="required|min:17|max:23"
                                type="text"
                                id="cardNumber"
                                name="Número do cartão"
                                label="Número do cartão"
                                v-model="form.cardNumber"
                                :value="format(form.docNumber)"
                                hint="Digite somente os números"
                                outlined
                                classes="mb-3"
                                :disabled="success"

                                data-checkout="cardNumber"
                                onselectstart="return false" onpaste="return false"
                                oncopy="return false" oncut="return false"
                                ondrag="return false" ondrop="return false" autocomplete=off
                              />
                              <!-- <input
                                type="hidden"
                                id="cardNumber"
                                data-checkout="cardNumber"
                                :value="format(form.cardNumber)"
                              /> -->

                              <v-expand-transition>
                                <v-alert type="error" v-if="errors.wrongCard.value" class="mt-n9">
                                  {{errors.wrongCard.msg}}
                                </v-alert>
                              </v-expand-transition>

                              <VeeTextField
                                rules="required|min:3|max:255"
                                type="text"
                                id="cardholderName"
                                name="Nome do titular"
                                label="Nome do titular"
                                v-model="form.cardholderName"
                                :value="form.cardholderName"
                                hint="Digite o nome completo impresso no cartão"
                                outlined
                                classes="mb-3"
                                :disabled="success"
                                data-checkout="cardholderName"
                              />

                              <v-row>
                                <v-col cols="12" sm="6" class="pb-0 pb-sm-3">
                                  <v-row>
                                    <!-- Data de validade MM/AA -->
                                    <v-col>
                                      <VeeSelect 
                                        rules="required"
                                        :items="months"
                                        v-model="form.expiryMonth"
                                        label="MM"
                                        outlined
                                        id="expiryMonth"
                                        :disabled="success"
                                        :value="form.expiryMonth"
                                        @change="fixCardJsExpiry()"
                                        @input="fixCardJsExpiry()"
                                        hint="Mês de validade"
                                      />
                                    </v-col>
                                    <v-col>
                                      <VeeSelect 
                                        rules="required"
                                        :items="years"
                                        v-model="form.expiryYear"
                                        label="AA"
                                        outlined
                                        id="expiryYear"
                                        
                                        :disabled="success"
                                        :value="form.expiryYear"
                                        @change="fixCardJsExpiry()"
                                        @input="fixCardJsExpiry()"
                                        hint="Ano de validade"
                                      />
                                      <input
                                        type="hidden"
                                        id="cardExpirationMonth"
                                        data-checkout="cardExpirationMonth"
                                        :value="form.expiryMonth"
                                      />
                                      <input
                                        type="hidden"
                                        id="cardExpirationYear"
                                        data-checkout="cardExpirationYear"
                                        :value="form.expiryYear"
                                  />
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" sm="6" class="pb-0 pb-sm-3">
                                  <VeeTextField
                                    rules="required|min:3|max:4"
                                    type="text"
                                    id="securityCode"
                                    label="Código de segurança"
                                    v-model="form.securityCode"
                                    :value="form.securityCode"
                                    outlined
                                    classes="mb-3"
                                    :disabled="success"
                                    data-checkout="securityCode" 
                                    onselectstart="return false" onpaste="return false"
                                    oncopy="return false" oncut="return false"
                                    ondrag="return false" ondrop="return false" autocomplete=off
                                  />
                                  <!-- {{form.cvc}} -->
                                </v-col>
                              </v-row>

                              <v-expand-transition>
                                <VeeSelect 
                                  v-if="!hiddenFields.includes('issuer')"
                                  v-show="issuers.length > 0"
                                  id="issuer"
                                  name="issuer"
                                  label="Banco emissor"
                                  data-checkout="issuer"
                                  :items="issuers"
                                  :v-model="form.issuer"
                                  :value="form.issuer"
                                  outlined
                                  :disabled="success"
                                />
                              </v-expand-transition>
                              <v-expand-transition>
                                <VeeSelect
                                  v-show="installments.length > 0"
                                  id="installments"
                                  name="installments"
                                  label="Opções de parcelamento"
                                  :items="installments"
                                  v-model="form.installments"
                                  outlined
                                  :disabled="success"
                                />
                            </v-expand-transition>
                          </v-col>
                        </v-row>
                      </v-window-item>

                      <!-- PIX -->
                      <v-window-item :value="3" eager>
                        <v-row v-if="paymentType === 3">
                          <v-col cols="12" md="12" class="pt-0 mt-0 mb-6">
                            <CardSubtitle :disabled="success">
                              Para pagar com o Pix, basta escanear, com o aplicativo do seu banco, o QRCode que iremos gerar para a sua compra.
                            </CardSubtitle>
                          </v-col>
                        </v-row>
                      </v-window-item>
                    </v-window>

                    <!-- Info antes do botão de compra -->
                    <BuyInfoBox 
                      :form="form"
                    />
                    
                  </v-card-text>
                  <template #actions>
                    <v-btn
                      id="BuyBtn"
                      large
                      block
                      @click="doPay()"
                      color="success"
                      dark
                      class="px-3 py-6 mr-n2 mb-3 text-h6"
                      v-show="!success"
                    >
                      {{BuyBtnText}}
                    </v-btn>
                  </template>
                  <template #end>
                    <div 
                      v-if="!success" 
                      class="d-flex justify-center align-center pb-3"
                      :style="$store.state.layout.singleCol && $store.state.window.width >= 830 ? 'display: none !important':''"
                    >
                      <img 
                        alt="Mercado Pago"
                        :src="require('@/assets/images/mercadopago-vert.png')"
                        height="100"
                        class="mx-sm-2"
                      />
                      <ShieldBadge
                        text="Compra segura"
                        color="green"
                        class="mx-sm-2"
                      >
                        <v-icon dark>mdi-shield-check</v-icon>
                      </ShieldBadge>

                      <ShieldBadge
                        text="Dados protegidos"
                        color="blue lighten-1"
                        class="mx-sm-2"
                      >
                        <v-icon dark>mdi-lock-check</v-icon>
                      </ShieldBadge>

                    </div>
                  </template>
                </Card>
              </v-col>
            </v-row>

            <input
              type="hidden"
              name="payment_method_id"
              id="payment_method_id"
              :value="form.payment_method_id"
            />
            <input
              type="hidden"
              name="transaction_amount"
              id="transaction_amount"
              :value="form.transaction_amount"
            />
            <!-- <input type="hidden" value="1" name="installments" /> -->

          </v-form>
          </ValidationObserver>
        </v-window-item>

        <!-- Success -->
        <v-window-item :value="2">
          <v-row>
            <v-col>
              <PaymentSuccess 
                ref="paymentSuccess"
                :success="success"
                :paymentType="paymentType"
                :boletoUrl="boletoUrl"
                :qrcode="qrcode"
                :form="form"
                :installments="installments"
                :hasError="hasError"
                :errors="errors"
                @showBoleto="dialog=true"
              />
            </v-col>
          </v-row>
        </v-window-item>
        <PaymentFooter />
      </v-window>
    </v-fade-transition>
  </div>
</template>

<script>
  const debug = false;

  import * as Card from "card";
  import ItemSelector from "@/components/common/ItemSelector";
  import ShieldBadge from "@/components/common/ShieldBadge";
  import BuyInfoBox from "@/components/payment/BuyInfoBox";
  import PaymentDialog from "@/components/payment/PaymentDialog";
  import PaymentOverlay from "@/components/payment/PaymentOverlay";
  import PaymentSuccess from "@/components/payment/PaymentSuccess";
  import PaymentLoader from "@/components/payment/PaymentLoader";
  import PaymentFooter from "@/components/payment/PaymentFooter";

  export default {
    name: 'Payment',

    components: {
      ItemSelector,
      ShieldBadge,
      BuyInfoBox,
      PaymentDialog,
      PaymentOverlay,
      PaymentSuccess,
      PaymentLoader,
      PaymentFooter,
    },

    data: () => ({
      tokenMP: '',

      hiddenFields: [
        "cel",
        "issuer", 
      ],

      userType: 1,
      userTypes: [
        { value: 1, text: "Pessoa física", icon: "mdi-account" },
        { value: 2, text: "Pessoa jurídica", icon: "mdi-office-building" }
      ],

      paymentType: 2,
      paymentTypes: [
        { value: 1, text: "Boleto Bancário", icon: "mdi-text-box", ref: "FormBoleto" },
        { value: 2, text: "Cartão de Crédito", icon: "mdi-credit-card", ref: "FormCreditCard" },
        { value: 3, text: "Pix", icon: "$pix", ref: "FormPix" },
      ],

      issuers: [],
      installments: [],
      maxInstallments: 100,
      docTypes: [],

      form: {
        transaction_amount: null,
        description: '',
        payment_method_id: '',

        installments: 1,
        installment: 1,
        issuer: '',
        docType: 'CPF',
        docNumber: '',

        // PfPj
        name: '',
        email: '',
        email_conf: '',
        cel: '',

        //Credit Card
        cardNumber: '',
        cardholderName: '',
        expiryMonth: '',
        expiryYear: '',
        securityCode: '',

        //Boleto Address
        address:  {
          zip_code: '',
          street_name: '',
          street_number: '',
          neighborhood: '',
          city: '',
          federal_unit: ''
        },
      },

      dialog: false,
      boletoUrl: '',
      
      loading: false,
      success: false,

      qrcode: {
        url: '',
        img: '',
      },

      hasError: false,
      errors: {
        invalid: {
          value: false,
          msg: 'Por favor, preencha o formulário corretamente.'
        },
        boleto: {
          value: false,
          msg: 'Erro ao gerar boleto.'
        },
        payment: null,

        wrongCard: {
          value: false,
          msg: 'Esse número de cartão não é válido, por favor verifique se digitou corretamente.',
        },
        pix: {
          value: false,
          msg: 'Erro ao pagar com Pix, verifique se digitou a chave corretamente.'
        }
      },

      vForm: {},
      vErrors: {},

      checkStatusInterval: null,

      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: [],
      UFs: [
          {id: 1, name: "Acre", sigla: "AC"},
          {id: 2, name: "Alagoas", sigla: "AL"},
          {id: 3, name: "Amapá", sigla: "AP"},
          {id: 4, name: "Amazonas", sigla: "AM"},
          {id: 5, name: "Bahia", sigla: "BA"},
          {id: 6, name: "Ceará", sigla: "CE"},
          {id: 7, name: "Distrito Federal", sigla: "DF"},
          {id: 8, name: "Espírito Santo", sigla: "ES"},
          {id: 9, name: "Goiás", sigla: "GO"},
          {id: 10, name: "Maranhão", sigla: "MA"},
          {id: 11, name: "Mato Grosso", sigla: "MT"},
          {id: 12, name: "Mato Grosso do Sul", sigla: "MS"},
          {id: 13, name: "Minas Gerais", sigla: "MG"},
          {id: 14, name: "Pará", sigla: "PA"},
          {id: 15, name: "Paraíba", sigla: "PB"},
          {id: 16, name: "Paraná", sigla: "PR"},
          {id: 17, name: "Pernambuco", sigla: "PE"},
          {id: 18, name: "Piauí", sigla: "PI"},
          {id: 19, name: "Rio de Janeiro", sigla: "RJ"},
          {id: 20, name: "Rio Grande do Norte", sigla: "RN"},
          {id: 21, name: "Rio Grande do Sul", sigla: "RS"},
          {id: 22, name: "Rondônia", sigla: "RO"},
          {id: 23, name: "Roraima", sigla: "RR"},
          {id: 24, name: "Santa Catarina", sigla: "SC"},
          {id: 25, name: "São Paulo", sigla: "SP"},
          {id: 26, name: "Sergipe", sigla: "SE"},
          {id: 27, name: "Tocantins", sigla: "TO"}
      ]
    }),

    computed: {
      hasProduct() {
        return this.$store.state.product && this.$store.state.product.id;
      },
      payWindow() {
        return !this.success ? 1 : 2;
      },
      BuyBtnText() {
        let text = ''
         switch (this.paymentType) {
              case 1: 
                text = 'Gerar boleto bancário'
                break;
              case 3: 
                text = 'Gerar QR Code do Pix'
                break;
              default:
                text = 'Comprar agora'
          }
        return text;
      }
    },

    created() {
      this.setTokenMP();

      if (this.$store.state.dev) this.setDev(); 

    },

    mounted() {
      this.vForm = Object.assign({}, this.form);
      this.vErrors = Object.assign({}, this.errors);

      this.getDocTypes();
      this.mountCardView();
      this.populateDateFields();

      /** MercadoPago Events */
      document
        .getElementById('cardNumber')
        .addEventListener('keyup', this.guessPaymentMethod);
      document
        .getElementById('cardNumber')
        .addEventListener('change', this.guessPaymentMethod);

      this.getWrapperRect();
    },

    methods: {     
      setPaymentType(value) {
        this.paymentType = value;
      },
      mountCardView() {
        let el = document.querySelector('.card-wrapper');

        /** CardJs view */
        if (el) new Card({ 
          form: "form#paymentForm",
          container: ".card-wrapper",
          formSelectors: { 
            numberInput: "input#cardNumber",
            expiryInput: "input#expiryMonth, input#expiryYear",
            cvcInput: "input#securityCode",
            nameInput: "input#cardholderName"
          },
          width: 320,
          formatting: true,
          placeholders: {
            number: "•••• •••• •••• ••••",
            name: "Nome Completo",
            expiry: "••/••",
            cvc: "•••"
          }
        });
      },
      fixCardJsExpiry() {
        let el =  document.getElementsByClassName('jp-card-expiry')[0];

        let month = this.form.expiryMonth || "••";
        let year = this.form.expiryYear || "••";

        if (el) el.innerHTML = month + '/' + year;
      },
      populateDateFields() {
        let arr = [];
        let d = new Date();
        let y = d.getFullYear().toString().substring(2,4);
        y = parseFloat(y);

        for(let i = 0; i <= 20; i++){
          arr[i] = y.toString();
          y++
        }

        this.years = arr;
      },
      formatForm(form) {
        let f = Object.assign({}, form);
        f.cardNumber = this.format(f.cardNumber);
        f.docNumber = this.format(f.docNumber);
        // f.docNumber = f.docNumber.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '');
        f.address.zip_code = this.format(f.address.zip_code);
        return f
      },
      format(str) {
        if (str) return str.replace(/[/. -]+/g, "");
      },
      setTokenMP() {
        this.axios
        .get(`/mercadopago/token`)
        .then((resp) => {
          if (resp.data.token) {
            this.tokenMP = resp.data.token;
            window.Mercadopago.setPublishableKey(this.tokenMP);
            if (debug) console.log("Setting token Mercado Pago: ", this.tokenMP)
          }
        })
        .catch((err) => {
          console.log(err);
        });
      },
      getDocTypes() {
        window.Mercadopago.getIdentificationTypes((status, response) => {
          if (debug) console.log("getIdentificationTypes", status, response);
          if (status == 200) {
            this.docTypes = response;
          } else {
            console.log("mp.getDocTypes err", status, response);
          }
          this.setDocType()
        });
      },
      setDocType() {
        if (this.docTypes.length > 0 && this.form.docNumber) this.form.docType = this.format(this.form.docNumber).length > 11 
        ? this.docTypes[1].id //CNPJ
        : this.docTypes[0].id; //CPF
      },
      
      async validate() {
        let valid = false;

        if (this.$refs.observer) await this.$refs.observer.validate().then(success => {
          valid = success;
        });

        if (!valid) this.errors.invalid.value = true;

        return valid;
      },

      async doPay() {
        let valid = await this.validate();
        if (debug) console.log('valid: ', valid);
        
        if (valid) {

          //** Track payment with Pixels */
          if (window.fbq) {
            window.fbq('track', 'Purchase', {
              value: this.$store.state.product.price, 
              currency: 'BRL'
            });
          } 

          if (window.ympx) {
            window.ympx("event","payment_started", {
              value: this.$store.state.product.price,
              method: this.paymentTypeText(this.paymentType)
            });
          }

          /** Boleto */
          if (this.paymentType === 1 ) {
            this.createBoleto();
          }
          
          /** Cartão de crédito */
          else if (this.paymentType === 2 ) {
            let form = document.getElementById('paymentForm');
            if (debug) console.log("form ", form)
            this.loading = true;
            window.Mercadopago.createToken(
              form,
              // this.formatForm(this.form),
              this.sdkResponseHandler
            );
          }

          /** Pix */
          else if (this.paymentType === 3 ) {
            this.payPix();
          }
        }
      },

      createBoleto() {
        this.loading = true;
        
        let dataToSend = this.formatForm(this.form);
        dataToSend.transaction = localStorage.getItem('transaction');

        this.axios
        .post(`/mercadopago/pay/boleto`, dataToSend)
        .then(resp => {
          if (debug) console.log ("createBoleto ", resp)
          if (debug) console.log('boleto.url', resp.data.external_resource_url);

          if ( resp.data && resp.data.external_resource_url ) {
            this.boletoUrl = resp.data.external_resource_url;
            this.clearErrors();
            this.success = true;
            this.dialog = true;
          }
        }).catch(err=> {
          this.hasError = true;
          this.errors.boleto.value = true;
          this.dialog = true;
          console.log("createBoleto ", err);
        })
        .finally(() => {
          this.loading = false;
          localStorage.removeItem('transaction');
        });
      },
      payPix() {
        this.loading = true;
        this.clearErrors();

        let dataToSend = this.formatForm(this.form);
        dataToSend.transaction = localStorage.getItem('transaction');
        
        this.axios
        .post(`/mercadopago/pay/pix`, dataToSend)
        .then(resp => {
          if (debug) console.log ("payPix resp", resp.data)
          if ( resp.status === 200) {
            this.clearErrors();
            this.success = true;
            this.qrcode = {
              url: resp.data.qrcode,
              img: resp.data.qr_code_base64,
            }
            this.checkStatusInterval = setInterval(() => {
              this.checkStatus(resp.data.id);
            }, 10 * 1000)
            
          }
        }).catch(err=> {
          this.hasError = true;
          this.errors.pix.value = true;
          this.dialog = true;
          console.log("payPix err", err);
        })
        .finally(() => {
          this.loading = false;          
          localStorage.removeItem('transaction');
        });
      },
      sdkResponseHandler(status, response) {
        if (debug) console.log('mp.sdkResponseHandler response', {status, response}, this.formatForm(this.form));
        let erros = [];
        if (status != 200 && status != 201) {
          response.cause.forEach((el) => {
            switch (el.code) {
              case '326':
              case '325':
                erros.push(`Confira a data de validade do cartão.\n`);
                break;
              case '324':
              case '323':
              case '322':
                erros.push(`Confira seu documento.\n`);
                break;
              case '316':
                erros.push(`Por favor, digite um nome válido.\n`);
                break;
              case 'E302':
                erros.push(`Confira o código de segurança.\n`);
                break;
              case 'E301':
                erros.push(
                  `Há algo de errado com esse número. Digite novamente.\n`
                );
                break;
              case '224':
                erros.push(`Digite o código de segurança.\n`);
                break;
              case '221':
                erros.push(`Digite o nome e sobrenome.\n`);
                break;
              case '220':
                erros.push(`Informe seu banco emissor.\n`);
                break;
              case '214':
              case '213':
              case '212':
                erros.push(`Informe seu documento.\n`);
                break;
              case '209':
                erros.push(`Escolha um ano.\n`);
                break;
              case '208':
                erros.push(`Escolha um mês.\n`);
                break;
              case '205':
                erros.push(`Digite o número do seu cartão.\n`);
                break;
              case '011':
                erros.push(
                  `Ação inválida, o recurso está em um estado que não permite esta operação. Para mais informações, consulte o estado que possui o recurso.\n`
                );
                break;
            }
          });
          this.hasError = true;
          this.loading = false;
          this.errors.payment = [...new Set(erros)];
          window.Mercadopago.clearSession();
          this.dialog = true;
        } else {
          var form = document.querySelector('#paymentForm');
          var card = document.createElement('input');
          card.setAttribute('name', 'token');
          card.setAttribute('type', 'hidden');
          card.setAttribute('value', response.id);
          form.appendChild(card);

          if (response.id) this.sendPayment();
          else console.log("mp.sdkResponseHandler no token id", response.id)
        }
      },
      sendPayment() {
        if (debug) console.log('sendPayment init')
        if ( window && window.Mercadopago ) {
          window.Mercadopago.clearSession();
        }
        if (debug) console.log(this.extractFormData(document.querySelector('#paymentForm'), {}));
        let dataToSend = this.extractFormData(document.querySelector('#paymentForm'), {});
        dataToSend.transaction = localStorage.getItem('transaction');
        this.axios
          .post(
            '/mercadopago/pay/process',
            // this.formatForm(this.form)
            // document.getElementById('paymentForm');
            dataToSend
          )
          .then((resp) => {
            if (debug) console.log('sendPayment resp', resp.data);
            // if (resp.status === 200) {
              const retorno = resp.data;
              switch (retorno.status) {
                case 'rejected':
                  this.hasError = true;
                  break;
                case 'approved':
                  this.clearErrors();
                  this.success = true;

                  /** Track with YM Pixel */
                  if (window.ympx) window.ympx("event","payment_completed", {
                    id: retorno.id 
                  });

                  break;
                default:
                  this.hasError = true;
                  break;
              }
            // } else {
            //   this.hasError = true;
            // }
          })
          .catch((err) => {
            console.log(err);
            this.hasError = true;
            this.errors.payment = [err];
          })
          .finally(() => {
            this.dialog = true;
            this.loading = false;
            localStorage.removeItem('transaction');
          });
      },
      
      checkStatus(paymentId) {
        
        let transaction = localStorage.getItem('transaction');

        this.axios
        .post(`/mercadopago/checkstatus`, {id: paymentId, transaction})
        .then(resp => {
          if (debug) console.log('checkStatus: ', resp.data);
          if (resp.data.status == 'approved') {

            /** Stop looping checkStatus */
            clearInterval(this.checkStatusInterval);

            /** Show success msg */
            if (this.$refs.paymentSuccess) this.$refs.paymentSuccess.pixPaid = true;
            
            /** Track with YM Pixel */
            if (window.ympx) window.ympx("event","payment_completed", {
              id: paymentId
            });
          }
        }).catch(err=> {
          console.log("checkStatus err ", err);
        });

      },

      getFieldValue(field, opts) {
        let type = field.getAttribute('type');
        let id = field.getAttribute('id');
        if (type) {
          switch (type) {
            case 'checkbox':
              return field.checked;
            case 'number':
              return field.value.includes('.')
                ? parseFloat(field.value)
                : parseInt(field.value, 10);
          }
        }
        if (opts && opts[field.name] && opts[field.name].type) {
          switch (opts[field.name].type) {
            case 'int':
              return parseInt(field.value, 10);
            case 'float':
              return parseFloat(field.value);
          }
        }
        if (id == 'cardNumber' || id == 'docNumber') field.value = this.format(field.value);

        return field.value;
      },
      extractFormData(form, opts) {
        return Array.from(form.elements).reduce((data, element) => {
          return Object.assign(data, {
            [element.id || element.name ||element.label ]: this.getFieldValue(element, opts),
          });
        }, {});
      },
      guessPaymentMethod(event) {
        let cardnumber = this.form.cardNumber ? this.form.cardNumber.replace(/\s/gi, ''): '';

        if (window && window.Mercadopago && cardnumber && cardnumber.length >= 6) {
          let bin = cardnumber.substring(0, 6);
          if (debug) console.log('mp.checking cardnumber bin: ' + bin);
          window.Mercadopago.getPaymentMethod(
            {
              bin: bin,
            },
            this.setPaymentMethod
          );
        }
      },
      setPaymentMethod(status, response) {
        if (debug) console.log('mp.setPaymentMethod', {status, response});
        this.errors.wrongCard.value = false;
        if (status == 200) {
          let paymentMethodId = response[0].id;
          this.form.payment_method_id = paymentMethodId;
          // this.getInstallments();
          this.getIssuers();
        } else {
          if (debug) console.log('mp.setPaymentMethod err ', response);
          this.errors.wrongCard.value = true;
        }
      },
      getIssuers() {
        window.Mercadopago.getIssuers(
            this.form.payment_method_id,
            this.setIssuers
        );
      },
      setIssuers(status, response) {
        if (status == 200) {
            response.forEach( issuer => {
                this.issuers.push({
                  text: issuer.name,
                  value: issuer.id
                })
            });
            this.getInstallments();
        } else {
            console.log('issuers method info error: ', response);
        }
      },
      getInstallments() {
        if ( ! (window && window.Mercadopago) ) { 
          return;
        }
        window.Mercadopago.getInstallments(
          {
            payment_method_id: this.form.payment_method_id,
            amount: parseFloat(this.form.transaction_amount),
          },
          (status, response) => {
            if (status == 200) {
              response[0].payer_costs.forEach((installment) => {
                if (installment.installments <= this.maxInstallments) {
                  this.installments.push({
                    text: installment.recommended_message,
                    value: installment.installments,
                  });
                }
              });
            } else {
              console.log(response);
              this.alertNotification = true;
              this.notificationForm = this.wrongCardMsg;
            }
          }
        );
      },

      clearForm() {
        this.form = Object.assign({}, this.vForm);
        this.$refs.observer.reset();
        // this.success = false;
        // this.error = false;
      },
      clearErrors() {
        this.errors = Object.assign({}, this.vErrors);
      },
      getWrapperRect() {
        if (this.$refs.paymentWrapper) {
          let rect = this.$refs.paymentWrapper.getBoundingClientRect();
          this.$store.commit('setSingleColRect',{
            top: rect.top,
            right: rect.right,
            bottom: rect.bottom,
            left: rect.left,
            width: rect.width,
            height: rect.height,
            x: rect.x,
            y: rect.y
          });
        }
      },
      paymentTypeText(id) {
        if (this.paymentTypes.length > 0 && id) {
          let arr = this.paymentTypes.filter( pt => {
            return pt.value === id
          });
          if (arr && arr.length > 0) return arr[0].text;
        }
        return 'Forma de pagamento não selecionada'
      },
      setDev() {
        // this.$store.commit('setProduct', {
        //   id: 1234,
        //   price: 1234.5,
        //   quantity: 1,
        //   description: 'Nome do produto'
        // }) 
        
        this.form = {
          // transaction_amount: 5000,
          // description: 'Lancheira do X-Men',
          payment_method_id: '',
  
          installments: 1,
          installment: 1,
          docType: '',
          docNumber: '66147737040',
  
          name: 'Nome teste',
          email: 'jaoteste@gmailteste.com',
          email_conf: 'jaoteste@gmailteste.com',
          cel: '11123456789',
  
          // https://www.mercadopago.com.br/developers/pt/guides/online-payments/checkout-pro/test-integration
          cardNumber: '5031 4332 1540 6351',
          cardholderName: 'João Teste',
          expiryMonth: '11',
          expiryYear: '25',
          securityCode: '123',
  
          address:  {
            zip_code: '11111-111',
            street_name: 'Rua abcd',
            street_number: '1234',
            neighborhood: 'Bairro Xyz',
            city: 'São nunca',
            federal_unit: 'AL'
          }
        };
      }
    },
    watch: {
      "$store.state.product.id": function() {
        this.form.transaction_amount = this.$store.state.product.price * this.$store.state.product.quantity;
        this.form.description = this.$store.state.product.description;
      },
      paymentType(val) {
        if (debug) console.log("paymentType", val)
        this.$nextTick(()=>{
          if (val === 2) this.mountCardView();
        });
      }
    }
  }
</script>
<style>
#paymentWrapper .v-window__container {
  /* Fix bug that was hiding v-card box shadow */
  margin-right: 2px;
  margin-left: 2px;
}
</style>
