<template>
  <div class="text-h6">
    <!-- More than one item -->
    <template v-if="$store.state.product.quantity > 1">
      <v-row no-gutters>
        <v-col cols="12" sm="3" md="4" class="subtitle-1 d-flex align-center">
          <span>Produto</span>
        </v-col>
        <v-col class="ml-3 ml-sm-0">
          {{form.description}}
        </v-col>
      </v-row>
      <v-row no-gutters v-if="$store.state.product.quantity > 1">
        <v-col cols="12" sm="3" md="4" class="subtitle-1 d-flex align-center">
          <span>Preço por unidade</span>
        </v-col>
        <v-col class="ml-3 ml-sm-0 font-weight-regular">
          {{
            $store.state.product.price 
            | currency("R$", 2, {
            decimalSeparator: ",",
            thousandsSeparator: ".",
            spaceBetweenAmountAndSymbol: true
            })
          }}
        </v-col>
      </v-row>
      <v-row no-gutters v-if="$store.state.product.quantity > 1">
        <v-col cols="12" sm="3" md="4" class="subtitle-1 d-flex align-center">
          <span>Quantidade</span>
        </v-col>
        <v-col class="ml-3 ml-sm-0 font-weight-regular">
          {{$store.state.product.quantity}} {{$store.state.product.quantity > 1 ? 'unidades' : 'unidade'}}
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="3" md="4" class="subtitle-1 d-flex align-center">
          Total da compra
        </v-col>
        <v-col class="ml-3 ml-sm-0">
          {{
            form.transaction_amount 
            | currency("R$", 2, {
            decimalSeparator: ",",
            thousandsSeparator: ".",
            spaceBetweenAmountAndSymbol: true
            })
          }}
        </v-col>
      </v-row>
      <v-expand-transition v-if="false">
        <v-alert type="error" v-if="errors.invalid.value" class="mt-n0 mb-0" dismissible>
          {{errors.invalid.msg}}
        </v-alert>
      </v-expand-transition>
    </template>
    
    <!-- Only one item -->
    <template v-else>
      <v-row>
        <v-col class="d-flex">
          <v-avatar 
            :size="$vuetify.breakpoint.smAndUp ? 72 : 52"
            v-if="$store.state.product.img"
            class="mt-1"
          >
            <v-img :src="$store.state.product.img"></v-img>
          </v-avatar>
          <div class="d-flex flex-column justify-center ml-3 ml-sm-6">
            <h4>
              {{form.description}}
            </h4>
            <span>
              {{
                form.transaction_amount 
                | currency("R$", 2, {
                decimalSeparator: ",",
                thousandsSeparator: ".",
                spaceBetweenAmountAndSymbol: true
                })
              }}
            </span>
          </div>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
export default {
  name: 'BuyInfoBox',
  components: {
  },
  props: {
    form: Object,
  },
  data() {
    return {
    }
  },
  computed: {
  }
}
</script>