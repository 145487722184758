<template>
  <v-container
    id="errorMain"
    fill-height
    fluid
    :style="$store.state.layout.dark ? 'background-color: var(--v-ymblack-base);' : 'background-color: var(--v-lightBg-base)'"
    class="ma-0"
    :class="$store.state.layout.bgImage ? 'bgImage' : ''"
  >
    <CheckoutLogo />
    <v-row class="align-center justify-center">
      <v-col style="max-width:600px;">
        <v-card class="mx-auto pa-6 d-flex flex-grow-1 fill-height justify-center align-center" min-height="120">
          <v-card-text class="">
            <slot />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ErrorTemplate",
  components: {
  },
  props: {
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
    }
  },
  computed: {
  },
  mounted() {
  },
  created() {
  }
}
</script>
<style scoped>
#errorMain {
  position: fixed;
  top: 0;
  left: 0;
  height:100vh;
  width:100vw;
}
#errorMain.bgImage {
  background-image: url('./../../assets/images/bg1.jpg');
  background-repeat: no-repeat;
  background-size: contain;
}
.ymlogo {
  position: fixed;
  top: 30px;
  right: 30px;
}
@media only screen and (max-width: 600px) {
  #errorMain.bgImage {
    background-size: cover !important;
  }
  .ymlogo {
    top: 0px;
    right: 0px;
    width: 100%;
    padding-top: 10px;
    padding-right: 10px;
  }
}
</style>