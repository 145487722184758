import { extend, setInteractionMode } from 'vee-validate';
import { required, email, min, max, confirmed, min_value, size } from "vee-validate/dist/rules";
import CpfValidator from "@/plugins/cpf-validator";

setInteractionMode("eager");

extend("email", {
  ...email,
  message: "{_field_} tem que ser válido."
});

extend("required", {
  ...required,
  message: "{_field_} não pode estar vazio."
});

extend("min", {
  ...min,
  message: "Mínimo de {length} caracteres."
});

extend("max", {
  ...max,
  message: "Máximo de {length} caracteres."
});

extend("confirmed", {
  ...confirmed,
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "A confirmação precisa ser idêntica.",
});

extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "{target} A nova senha e a confirmação precisam ser idênticas.",
});

extend("min_value", {
  ...min_value,
  message: "Mínimo de R$ {min},00.",
});

extend("size", {
  ...size,
  message: "O arquivo não pode exceder {size} kb."
});

extend("cpf", {
  ...CpfValidator,
  message: "CPF inválido.",
});