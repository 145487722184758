<template>
  <v-dialog  
    v-model="model"
    :width="paymentType === 1 && boletoUrl ? '80vw' : 420" 
    persistent 
    :fullscreen="boletoUrl ? true : false"
    :scrollable="boletoUrl ? true : false"
  >
    <v-card>

      <!-- Show Boleto PDF -->
      <template v-if="paymentType === 1 && boletoUrl">
        <v-toolbar dense>
          <v-toolbar-title>
          Boleto bancário 
          <span v-if="$vuetify.breakpoint.smAndUp">
            - <span>{{form.description}}</span>
          </span>
          
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="$emit('close');"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text style="height:95vh" class="pa-0 ma-0">
          <v-expand-transition>
            <embed :src="boletoUrl" type="application/pdf" width="100%" style="height:100%;" v-show="boletoUrl ? true : false"/>
          </v-expand-transition>  
        </v-card-text>
      </template>
      
      <template v-else>
        <v-toolbar
          dark
          :color="hasError ? 'error' : 'success darken-1'"
          class="text-h6 font-weight-regular mb-3 d-flex align-center justify-space-between"
        >
          <v-icon
            dark
            class="mr-3"
          >
            {{hasError ? 'mdi-information-outline' : 'mdi-check-circle'}}
          </v-icon>
          {{hasError ? 'Erro ao processar pagamento' : 'Pagamento realizado!'}}
        </v-toolbar>

        <v-card-text class="mt-6 body-1">
          <template v-if="hasError">
            Problemas ao efetuar o pagamento, verifique os dados e tente novamente!
            <ul v-if="errors.payment">
              <li v-for="item in errors.payment" :key="item">{{ item }}</li>
            </ul>
          </template>

          <template v-else-if="success">
            <h3>
              Seu pagamento foi efetuado com sucesso!
            </h3>
          </template>
        </v-card-text>
      </template>

      <v-card-actions v-if="$vuetify.breakpoint.smAndUp">
        <v-btn text target="_blank" :href="boletoUrl" class="mr-3" v-if="paymentType === 1 && boletoUrl">
          Abrir o boleto em uma nova aba
          <v-icon right>mdi-open-in-new</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn 
          class="px-6"
          dark
          color="grey darken-2"
          @click="$emit('close')"
        >
          <template v-if="paymentType === 1  && boletoUrl">Fechar</template>
          <template v-else>Entendi</template>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'PaymentDialog',
  components: {
  },
  props: [
    "dialog",
    "success",
    "errors",
    "hasError",
    "paymentType",
    "boletoUrl",
    "form",
  ],
  data() {
    return {
    }
  },
  computed: {
    model() {
      return this.dialog;
    }
  }
}
</script>