<template>
  <div class="d-flex align-center mt-3 rounded" style="width:100%; position: relative;  border:1px solid rgba(0,0,0,0.15)">
    <v-card 
      flat
      color="#fff"
      class="px-3 grey--text text--darken-2"
      style="position:absolute; top:-12px; left:5px">{{text}}
    </v-card>
    <v-row no-gutters>
      <v-col cols="12" sm="11" class="pa-3">
        <code style="white-space: normal; word-wrap: anywhere; word-break: break-all;">{{code}}</code>
      </v-col>
      <v-col cols="12" sm="1" class="d-flex align-center justify-center">
        <CopyToClipboard 
          :text="text"
          :item="code"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CopyToClipboard from '@/components/common/CopyToClipboard.vue'

export default {
  name: 'CodeBox',
  components: {
    CopyToClipboard
  },
  props: {
    code: String,
    text: String
  },
  data() {
    return {
    }
  },
  computed: {
  }
}
</script>