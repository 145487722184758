<template>
  <v-overlay
    fixed
    opacity="0.4"
    :value="loading"
  >
    <v-card>
      <v-toolbar dark color="ymblue" class="text-h6">
        {{title}}
      </v-toolbar>
      <v-card-text class="text-h6 font-weight-regular pa-6 d-flex flex-row flex-nowrap align-center" style="background: #fff; color:#333">
      <v-progress-circular
        :size="60"
        color="ymblue"
        indeterminate
      ></v-progress-circular>
      <div style="max-width:400px;" class="pl-6">
        {{text}}
      </div>
      </v-card-text>
    </v-card>
  </v-overlay>
</template>
<script>
export default {
  name: 'PaymentOverlay',
  components: {
  },
  props: [
    "loading",
    "paymentType"
  ],
  data() {
    return {
    }
  },
  computed: {
    model() {
      return this.dialog;
    },
    title() {
      let text = ''
         switch (this.paymentType) {
              case 1: 
                text = 'Gerando boleto'
                break;
              case 3: 
                text = 'Gerando QR Code'
                break;
              default:
                text = 'Efetuando pagamento'
          }
        return text;
    },
    text() {
      let text = ''
         switch (this.paymentType) {
              case 1: 
                text = 'Por favor, aguarde enquanto seu boleto é gerado.'
                break;
              case 3: 
                text = 'Por favor, aguarde enquanto seu QR Code é gerado.'
                break;
              default:
                text = 'Por favor, aguarde enquanto seu pagamento é processado.'
          }
        return text;
    }
  }
}
</script>