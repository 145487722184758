import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import PixIcon from '@/components/icons/Pix.vue'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: false,
    themes: {
      light: {
        primary: '#20A4A3', // ymblue
        accent: '#CDDC39',
        secondary: '#8BC34A',
        success: '#8BC34A',
        info: '#03A9F4',
        warning: '#D76E52',
        error: '#c34a4e',
        ymblack: '#2B2B2B',
        ymorange: '#F87800',
        ymyellow: '#FFC400',
        ymblue: '#20A4A3',
        lightBg: '#f5f5f5'
      },
      dark: {
        ymblack: '#2B2B2B',
        ymorange: '#F87800',
        ymyellow: '#FFC400',
        ymblue: '#20A4A3',
      }
    }
  },
  icons: {
    values: {
      pix: {
        component: PixIcon,
      },
    },
  },
});
