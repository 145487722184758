<template>
  <v-item-group class="mt-0 d-flex flex-nowrap justify-center" v-model="vModel" mandatory>
    <v-item
      v-for="(item, i) in items"
      :key="'obj_card_' + i + 1"
      v-slot:default="{ active, toggle }"
      :value="item.value"
      class="d-flex flew-grow-1 flex-shrink-0 mr-2 mr-sm-6"
    >
      <v-card
        @click="toggle"
        flat
        :style="setStyles(active)"
        :disabled="disabled"
        :width="$vuetify.breakpoint.smAndUp ? 120 : 100"
        :height="$vuetify.breakpoint.smAndUp ? 100 : 80"
        :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : '' "
        >
        <v-card-text 
          class="text-center d-flex flex-column align-center justify-center pa-0 body-1"
          
        >
          <v-icon v-if="item.icon" :color="setIconColor(active)">{{ item.icon }}</v-icon>
          <span class="mt-1" :class="setTextColor(active)" style="line-height:1rem;">
            {{ item.text }}
          </span>
        </v-card-text>
      </v-card>
    </v-item>
  </v-item-group>
</template>
<script>
export default {
  name: "ItemSelector",
  props: {
    model: Number,
    items: Array,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      vModel: null
    };
  },
  created() {
    this.vModel = this.model;
  },
  methods: {
    setIconColor(active) {
      return active ? 'ymblue' : '';
    },
    setStyles(active) {
      return active
      ? 'border: 2px solid var(--v-ymblue-base) !important;'
      : 'border: 1px solid rgba(0,0,0,0.15) !important;'

    },
    setTextColor(active) {
      return !active ? 'grey--text text--darken-1' : 'ymblue--text text--darken-2';
    }
  },
  watch: {
    vModel() {
      this.$emit("update", this.vModel);
    }
  }
};
</script>
