<template>
  <v-card 
    id="footer"
    flat
    class="mt-12"
    color="transparent"
    :dark="$store.state.layout.dark ? true : false"
  >
    <v-divider/>
    <v-card-text class="px-3 text-center">
      <div class="mx-auto" style="width:200px" v-if="!$store.state.layout.singleCol || $store.state.window.width < 830">
        <CheckoutLogo />
      </div>
      A YMonetize está processando este pedido a serviço de {{$store.getters.seller.name}}.
      <br/>
      Ao continuar, você concorda com os <a href="" target="_blank">Termos de compra</a>
      e com a 
      <a href="https://www.ymonetize.com/politicas-de-privacidade.html" target="_blank">Política de privacidade</a>
      .
      <br />
      <br />

      <span class="overline" style="line-height: 1rem;">YMonetize &copy; {{new Date().getFullYear()}}</span>
      
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "PaymentFooter",
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  created() {
  },
  methods: {
  },
  watch: {
  }
};
</script>
