<template>
  <v-app id="app" 
    :style="$store.state.layout.dark ? 'background-color: var(--v-ymblack-base);' : 'background-color: var(--v-lightBg-base)' " 
    :class="$store.state.layout.bgImage ? 'bgImage' : ''" >
    <v-main :style="$store.state.layout.bgImage ? 'background-color:rgba(0,0,0,0.25)' : ''">
      <v-container class="pa-1 pa-sm-3">
        <BgLogoCol />
        <Payment ref="payment"/>  
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Payment from '@/components/Payment'
import BgLogoCol from "@/components/payment/BgLogoCol";

export default {
  name: 'App',

  components: {
    Payment,
    BgLogoCol
  },

  data: () => ({
    title: "Checkout YMonetize"
  }),

  created() {
    document.title = this.title;
    
    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.initFbPixel();
  },
  
  mounted() {
    /**
     * Receive product id through query params
     * Exemplo
     * /?pid=513247
     */
    let urlParams = new URLSearchParams(window.location.search);
    let pid = urlParams.get('pid');
    let extraKeys = {};
    for(var pair of urlParams.entries()) {
      if ( pair[0] !== 'pid' ) {
        extraKeys[pair[0]] = pair[1];
      }
    }

    if (pid) {
      this.getProduct(pid, extraKeys);
    } else {
      this.$store.commit('setInitialLoading', false);
    }
  },

  methods: {
    getProduct(id, extraKeys) {
      let transaction = localStorage.getItem('transaction');
      this.axios
        .post('/product/' + id, {extraKeys, transaction})
        .then((resp) => {
          this.initTransaction(resp.data.transaction)
          this.setProduct(resp.data);
        })
        .catch((err) => {
          console.log(err);
          this.$store.commit('setInitialLoading', false);
        });
    },

    initTransaction(transaction){
      if(transaction === undefined || transaction === '') return;

      let localTransaction = localStorage.getItem("transaction");
      
      if (localTransaction === null) {
        localStorage.setItem('transaction', JSON.stringify(transaction));
        return transaction;
      }
      return localTransaction;
    },

    async setProduct(data){
      let product = data.product;
      
      await this.$store.commit('setProduct', product);
      this.$store.commit('setInitialLoading', false);

      this.initYmPixel();
    },
    handleResize() {
      this.$store.commit('setWindowSize', {
        width:window.innerWidth,
        height:window.innerHeight,
      })
      if (this.$refs.payment) this.$refs.payment.getWrapperRect();
    },
    initFbPixel() {
      var cfg = this.$store.state.fbPixel;
      var codes = cfg.codes;

      let urlParams = new URLSearchParams(window.location.search);
      let pcode = urlParams.get('px')

      if ( pcode && pcode !== null ) {
        this.$store.commit('addFbPixelCode', pcode);
      }

      if (!window.fbq) return; // failsafe

      if (codes && codes.length > 0) {
        for(var i=0,il=codes.length;i<il;++i) {
          window.fbq('init', codes[i]);
          var img = document.createElement('img');
          img.height="1";
          img.width="1";
        }
      }
      /** Track page view */
      window.fbq('track', 'InitiateCheckout');
    },
    initYmPixel() {
      let pid = this.$store.getters.product.id;
      if (!window.ympx || !pid) return;
      window.ympx("init","YCHK-$" + pid);
      window.ympx("event","pageload");
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  }

};

</script>
<style lang="css">
.v-input__slot {
  background-color: white !important;
}
#app.bgImage {
  background-image: url('./assets/images/bg4.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: contain;
}
@media only screen and (max-width: 600px) {
  #app.bgImage {
    background-size: cover !important;
  }
}
</style>
