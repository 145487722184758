import CpfValidate from '@/plugins/cpf-rules'

const validator = {
  getMessage(field, args) { // will be added to default English messages.
    return 'Número de CPF inválido'
  },
  validate(value, args) {
    return CpfValidate(value)
  }
}
export default validator