<template>
  <div
    class="ymlogo d-flex flex-column justify-start align-end pb-3"
    :style="$store.state.layout.dark ? 'background-color: var(--v-ymblack-base)' : ''"
  >
    <div :style="{'margin-bottom': margin }">
      <img alt="YMonetize"
        :src="$store.state.layout.dark ? require('@/assets/logos/logo_ymonetize_negativo_RGB.png') : require('@/assets/logos/logo_ymonetize_positivo_RGB.png')"
        width="100%" 
        :style="{'max-width': width + 'px'}"
      />
    </div>
    <svg viewBox="0 0 95 20" :style="{'max-width': width + 'px', 'letter-spacing': '0.2px'}" class="font-weight-medium">
      <text text-anchor="end" x="82" y="15" color="red" :fill="$store.state.layout.dark ? '#fff' : '#2B2B2B'" :style="{'font-size': '12px'}">Checkout</text>
    </svg>
  </div>
</template>
<script>
export default {
  name: "CheckoutLogo",
  props: {
    width: {
      type: Number,
      default: 200
    }
  },
  data() {
    return {

    }
  },
  computed: {
    margin() {
      return '-' + (this.width * 0.3) / 2 + 'px'
    }
  }
}
</script>
<style scoped>
svg {
  width: 100%;
}
.resize{
  font-size: 25vw !important;
  /* font-size: 10vmax;
  font-size: 1vmin; */
  width: 100%;
  display:inline-block;

}    
.textspan{
  font-size: 1vw !important;
  /* display:inline-block;
  width:100%;
  height:auto; */
}
</style>